// src/contexts/UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [file, setFile] = useState(null); // Add file state here

  const [isSignupOpen, setIsSignupOpen] = useState(false); // Signup modal state

  const onSignupOpen = () => setIsSignupOpen(true);  // Open the modal
  const onSignupClose = () => setIsSignupOpen(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user); // User is logged in
      } else {
        setUser(null); // User is logged out
        setFile(null); // Clear file on logout
      }
    });

    return () => unsubscribe(); // Clean up the listener
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null); // Ensure the user state is cleared
      setFile(null); // Clear file on logout
      console.log("User signed out successfully.");
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  };

  return (
    <UserContext.Provider value={{ user, setUser, file, setFile, handleLogout, isSignupOpen,
      onSignupOpen,
      onSignupClose }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import ChatInput from './components/Chat';
import Login from './components/Login';
import Chat from './components/Chat';
import CoverLetter from './components/CoverLetter';
import './App.css';
import { UserProvider } from './contexts/UserContext';
import InterviewQuestions from './components/InterviewQuestions';
import SettingsPage from './components/Settings';
import Footer from './components/Footer'
import AboutUs from './components/AboutUs';
import TermsAndConditions from './components/Terms';

const App = () => {
  return (
    <UserProvider>
    <div className="appContainer">
      <Router>
        <Navbar />
        <div className="app-content">
          <Routes>
            <Route path="/" element={<InterviewQuestions />} />
            <Route path="/cover-letter" element={<CoverLetter/>} />
            {/* <Route path="/resume-assistant" element={<ResumeAssistant />} /> */}
            <Route path="/login" element={<Login />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/terms" element={<TermsAndConditions />} />
          </Routes>
        </div>
        <Footer /> {/* Add Footer here, after Routes */}
        </Router>
    </div>
    </UserProvider>
  );
};

export default App;

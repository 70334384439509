import React from 'react';
import './Terms.css';

function TermsAndConditions() {
  return (
    <div className="terms-container">
      <h1 className="terms-title">PositionPrep Terms of Service</h1>
      
      <p>Welcome to PositionPrep! These Terms of Service ("Terms") govern your use of PositionPrep, an AI-powered application designed to assist with interview preparation and cover letter generation based on user-provided resumes and job position descriptions ("PDs"). By accessing or using PositionPrep, you agree to comply with these Terms.</p>
      
      <h2>1. Data Storage and Privacy</h2>
      <p>PositionPrep uses Firebase to securely store user data, including resumes and PDs. For more information on how we handle user data, please see our Privacy Policy.</p>

      <h2>2. Service Usage and Subscription</h2>
      <p><strong>Free Access:</strong> PositionPrep allows one free use per day, which refreshes every 24 hours.</p>
      <p><strong>PositionPrep Plus:</strong> For $15 per month, users receive up to 150 daily uses of the enhanced interview and cover letter generation tools.</p>
      <p><strong>Subscription Cancellation:</strong> Users may cancel their subscription at any time through the app’s settings page. Upon cancellation, users retain access to PositionPrep Plus until the end of their current billing cycle. Note: PositionPrep does not offer refunds for canceled subscriptions.</p>

      <h2>3. User Content and Ownership</h2>
      <p>Users retain ownership of all content they upload, including resumes and PDs.</p>
      <p>By uploading a resume or PD, you grant PositionPrep a limited, non-exclusive right to store, parse, and process your content to provide the intended service. This includes parsing your resume text and sending it to our AI for processing. PositionPrep does not use, sell, or distribute user content outside of service delivery.</p>

      <h2>4. User Responsibilities</h2>
      <p><strong>Real Information:</strong> To ensure the best results, users agree to provide accurate and truthful resumes and PDs.</p>
      <p><strong>Content Validity:</strong> Users may not upload content that is false, misleading, or unrelated to the intended purpose of interview preparation.</p>

      <h2>5. AI Limitations and Disclaimers</h2>
      <p>PositionPrep uses AI to generate interview questions and cover letters based on provided content. However, PositionPrep does not guarantee the accuracy, relevance, or success of AI-generated content.</p>
      <p>AI-generated responses are for educational and preparatory purposes only. Users should review and modify all generated content to suit their individual needs.</p>

      <h2>6. Limitation of Liability</h2>
      <p>PositionPrep is not liable for any direct, indirect, incidental, or consequential damages that may arise from using or inability to use the app, including but not limited to, any reliance on AI-generated content.</p>

      <h2>7. Changes to Terms</h2>
      <p>PositionPrep reserves the right to update these Terms at any time. Changes will be effective upon posting on our website or in the app. Continued use of the service signifies acceptance of updated Terms.</p>
      
      <p>If you have any questions regarding these Terms, please contact us at [contact email].</p>
    </div>
  );
}

export default TermsAndConditions;

// src/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, provider, signInWithPopup, signInWithEmailAndPassword } from '../firebase';
import { useUser } from '../contexts/UserContext';
import './Login.css';

const Login = ({ isOpen, onClose, onSignupOpen }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { setUser } = useUser();
  const navigate = useNavigate();

  if (!isOpen) return null;

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      //navigate('/');
      onClose();
    } catch (error) {
      console.error("Error during Google sign-in:", error);
    }
  };

  const handleEmailPasswordSignIn = async (e) => {
    e.preventDefault();
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      setUser(result.user);
      navigate('/');
      onClose();
    } catch (error) {
      console.error("Error during email/password sign-in:", error);
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="login-modal" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>×</button>
        <h2 className="login-title">Login</h2>
        
        <form onSubmit={handleEmailPasswordSignIn} className="login-form">
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="login-input"
            />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="login-input"
            />
          </div>
          
          <button type="submit" className="login-button">
            Sign in with Email
          </button>

          <button type="button" className="login-button google" onClick={handleGoogleSignIn}>
            Sign in with Google
          </button>
        </form>
        {/* Link to open the Signup modal */}
        <p className="signup-link">
          Don't have an account?{" "}
          <a href="#signup" onClick={(e) => {
            e.preventDefault();
            onClose(); // Close the Login modal
            onSignupOpen(); // Open the Signup modal
          }}>
            Sign up
          </a>
        </p>
      </div>
    </div>
  );
};

export default Login;

import React from "react";
import Chat from "./Chat";

function InterviewQuestions() {
  const chatPath = "chats"; // Firestore path for interview questions

  return (
    <div className="interview-questions-page">
      <Chat 
        rule="interviewQuestions"
        chatPath={chatPath} 
        heading="Interview Question Generator" 
      />
    </div>
  );
}

export default InterviewQuestions;


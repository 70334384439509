import React, { useEffect } from 'react';
import { FaFilePdf } from 'react-icons/fa';
import { useUser } from '../contexts/UserContext';

const FilePreview = ({ file, setFile }) => {
  const { user } = useUser();

  // Clear the file when user logs out
  useEffect(() => {
    if (!user) {
      setFile(null); // Clear file preview if user logs out
    }
  }, [user, setFile]); // Dependency on `user` and `setFile`

  return (
    <div className="file-preview">
      <FaFilePdf className="file-icon" />
      <div className="file-info">
        <span className="file-name">{file.name}</span>
        {file.url && (
          <a href={file.url} target="_blank" rel="noopener noreferrer">
            View Resume
          </a>
        )}
      </div>
    </div>
  );
};

export default FilePreview;

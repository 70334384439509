import React from "react";
import Chat from "./Chat";

function CoverLetter() {
  const chatPath = "coverLetters"; // Firestore path for cover letters

  return (
    <div className="cover-letter-page">
      <Chat 
        rule="coverLetter"
        chatPath={chatPath} 
        heading="Cover Letter Generator" 
      />
    </div>
  );
}

export default CoverLetter;

import React from 'react';
import './AboutUs.css';

function AboutUs() {
  return (
    <div className="about-container">
      <h1 className="about-title">About Us</h1>

      <p className="about-description">
        Welcome to <strong>PositionPrep</strong>, your personal AI-powered career assistant! We know that job applications can be challenging, especially when it comes to tailoring your resume, crafting engaging cover letters, and preparing for interviews. Our mission is to simplify this process, giving you the tools to confidently showcase your strengths and land the job you deserve.
      </p>

      <section className="about-section">
        <h2 className="section-title">What We Do</h2>
        <p className="section-content">
          At <strong>PositionPrep</strong>, we combine cutting-edge AI technology with a deep understanding of what employers are looking for in candidates. Our app uses your resume and a job position description (PD) to generate tailored interview questions and cover letter content. By analyzing the specific requirements of the job, our AI crafts responses and insights that help you prepare more effectively.
        </p>
      </section>

      <section className="about-section">
        <h2 className="section-title">Our Vision</h2>
        <p className="section-content">
          We believe that everyone deserves the best chance to succeed in their career journey. With <strong>PositionPrep</strong>, we aim to make advanced career preparation accessible, effective, and even enjoyable. Whether you’re applying for your first job or making a career change, our goal is to empower you with personalized support.
        </p>
      </section>

      <section className="about-section">
        <h2 className="section-title">How It Works</h2>
        <ul className="section-content">
          <li><strong>Upload Your Resume:</strong> Start by uploading your resume or keeping an up-to-date one on file.</li>
          <li><strong>Paste the Position Description (PD):</strong> Each time you’re applying for a new role, paste the PD, and our AI gets to work.</li>
          <li><strong>Get Ready with Targeted Questions:</strong> Receive interview questions and suggested answers that align with the job requirements.</li>
          <li><strong>Craft Winning Cover Letters:</strong> Generate cover letters that are specific to each application.</li>
        </ul>
      </section>

      <section className="about-section">
        <h2 className="section-title">Why Choose Us?</h2>
        <p className="section-content">
          With a mix of advanced technology and a user-friendly interface, <strong>PositionPrep</strong> offers a unique, personalized experience to make you stand out from the crowd. We provide one free use of the service to get you started, with options to unlock more advanced features when you’re ready.
        </p>
      </section>

      <p className="about-description">
        Thank you for choosing <strong>PositionPrep</strong> as your career partner. We’re excited to be a part of your journey!
      </p>
    </div>
  );
}

export default AboutUs;

import React, { useState, useEffect, useRef } from "react";
import { FaEllipsisV } from "react-icons/fa";
import "./Chat.css";
import './FilePreview.css';
import { firestore, auth } from "../firebase";
import { onAuthStateChanged } from 'firebase/auth';
import { collection, doc, addDoc, getDocs, getDoc, updateDoc, orderBy, query } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase"; // Firebase Functions instance
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import DOMPurify from 'dompurify';
import PaymentModal from './PaymentModal'; // Update the path as needed


function Chat({ rule, chatPath, heading }) {
  const [chatMessages, setChatMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");
  const [responseReceived, setResponseReceived] = useState(false);
  const [userId, setUserId] = useState(null);
  const [showMainSection, setShowMainSection] = useState(true);
  const [mainSectionContent, setMainSectionContent] = useState({
    title: "Please upload your resume and a position description",
    description: "to receive tailored interview questions and answers.",
  });
  const [chats, setChats] = useState([]);
  const [currentChatId, setCurrentChatId] = useState(null);
  const chatContainerRef = useRef(null);
  const { user, onSignupOpen } = useUser();
  const [sidebarOpen, setSidebarOpen] = useState(false); // State to control sidebar visibility
  const [isMobile, setIsMobile] = useState(false); // Detect screen size
  const [editingChatId, setEditingChatId] = useState(null);
  const [editedTitle, setEditedTitle] = useState("");


  const [showPopup, setShowPopup] = useState(false); // Popup for usage limit
  const navigate = useNavigate(); // Navigation for redirect

  const fetchChats = async (userId, chatPath) => {
    try {
      const chatsRef = collection(firestore, "users", userId, chatPath);
      const chatsQuery = query(chatsRef, orderBy("timestamp", "desc"));
      const chatSnapshots = await getDocs(chatsQuery);

      const chatList = chatSnapshots.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      
      setChats(chatList);
    } catch (error) {
      console.error("Error fetching chats:", error.message);
      console.dir(error);
    }
  };

  const fetchParsedResumeText = async (userId) => {
    const resumeDocRef = doc(firestore, "uploads", userId, "parsedDocuments", "parsedResume");
    const resumeDocSnap = await getDoc(resumeDocRef);
    return resumeDocSnap.exists() ? resumeDocSnap.data().content : null;
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        checkResumeUploaded(user.uid);
      } else {
        setUserId(null);
        setChats([]);
      }
    });
    return () => unsubscribe();
  }, []);

  // Fetch chats only when userId and chatPath are defined
  useEffect(() => {
    if (userId && chatPath) {
      fetchChats(userId, chatPath);
    }
  }, [userId, chatPath]); 

  useEffect(() => {
    // Detect if the screen is mobile-sized
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Mobile breakpoint
    };
    handleResize(); // Check on initial load
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    async function fetchUserData() {
      const userDocRef = doc(firestore, "users", userId, "settings", "subscription");
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const { usage, subscription } = userDocSnap.data();
        // Update state with usage and subscription
      }
    }
    if (userId) fetchUserData();
  }, [userId]);
  

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

// This method accepts cloudFunction as a parameter to dynamically choose the Cloud Function to call
  async function callCloudFunction(data) {
    try {
      // Create a callable instance with the specified cloud function
      const callableFunction = httpsCallable(functions, 'generateContent');

      // Call the function with the provided data
      //Error is thrown here
      const result = await callableFunction(data);
      return result.data; // Return the response data to be used by the caller
    } catch (error) {
      console.error("Error calling cloud function:", error); // Log the complete error object
      throw error; // Rethrow error for additional context
    }
  }

  const checkResumeUploaded = async (userId) => {
    try {
      // Reference the Firestore document
      const userDocRef = doc(firestore, "users", userId, "settings", "resumeStatus");

      // Fetch the document
      const docSnap = await getDoc(userDocRef);

      if (docSnap.exists()) {
        const { resumeUploaded } = docSnap.data(); // Extract the flag from the document

        if (resumeUploaded) {
          // If resume is uploaded, update mainSectionContent to the new message
          setMainSectionContent({
            title: "Resume uploaded. Now paste the position description below.",
            description: "This will help generate tailored interview questions.",
          });
          setShowMainSection(true);
        }
      } else {
        console.log("No resumeStatus document found for this user.");
      }
    } catch (error) {
      console.error("Error checking resume uploaded status:", error);
    }
  };

  const handleSendMessage = async () => {
    if (!user) {
      onSignupOpen();
      return;
    }
  
    setMainSectionContent(null);
  
    setChatMessages(prev => [...prev, { text: message, sender: 'user' }]);
    setMessage(""); // Clear the input
  
    try {
      // Check if a resume is uploaded
      const userDocRef = doc(firestore, "users", userId, "settings", "resumeStatus");
      const docSnap = await getDoc(userDocRef);

      if (!docSnap.exists() || !docSnap.data().resumeUploaded) {
        const errorMessage = "Please upload a resume to proceed.";
        setResponse(errorMessage);
        setChatMessages((prev) => [...prev, { text: errorMessage, sender: 'bot' }]);
        return; // Exit early if no resume is uploaded
      }
      // Resume is uploaded; proceed with fetching parsed resume text
      const parsedResumeText = await fetchParsedResumeText(userId);
      if (!parsedResumeText) {
        const errorMessage = "Resume parsing failed. Please try again later.";
        setResponse(errorMessage);
        setChatMessages((prev) => [...prev, { text: errorMessage, sender: 'bot' }]);
        return; // Exit early if parsing fails
      }
    
      console.log("Validating message content...");
      if (!message || message.length < 300 || !/\b(responsibilities|qualifications|experience|requirements)\b/i.test(message)) {
        const errorMessage = "Please paste your position description so I can assist you to prepare for your interview.";
        setResponse(errorMessage);
        setChatMessages(prev => [...prev, { text: errorMessage, sender: 'bot' }]);
        console.log("Invalid message content:", errorMessage);
        return; // Exit early
      }
    
      if (!message || message.length > 5000) {
        console.error("Invalid message length");
        return; // Exit early
      }
    
      const safeMessage = DOMPurify.sanitize(message);
    
      console.log("Preparing request data...");
      const requestData = { resume: parsedResumeText, message: safeMessage, rule: rule };
    
      const response = await callCloudFunction(requestData);
    
      if (response.status === "LIMIT_REACHED") {
        setShowPopup(true);
        console.log("Limit reached, showing popup.");
        return; // Exit early
      }
    
      const gptResponse = response.content;
      setChatMessages(prev => [...prev, { text: gptResponse, sender: 'bot' }]);
      setResponse(""); // Clear any existing response
      setResponseReceived(true);
    
      if (gptResponse) {
        const title = (gptResponse.match(/(\*\*Company Name:\*\*|Company Name:)\s*(\S+)/) || [])[2]?.replace(/\*\*$/, '').trim() || "Untitled Chat";
        await addDoc(collection(firestore, "users", userId, chatPath), {
          title,
          content: gptResponse,
          message,
          timestamp: new Date(),
        });
    
        if (userId && chatPath) {
          fetchChats(userId, chatPath);
          console.log("Chats fetched successfully.");
        } else {
          console.error("userId or chatPath is undefined, fetchChats not called.");
        }
      }
    } catch (error) {
      console.error("Error occurred:", error);
      const fallbackMessage = "An unexpected error occurred. Please try again later.";
      setChatMessages(prev => [...prev, { text: fallbackMessage, sender: 'bot' }]);
    }    
  };

  const loadChat = async (chatId) => {
    try {
      const chatRef = doc(firestore, "users", userId, chatPath, chatId);
      const chatDoc = await getDoc(chatRef);
  
      if (chatDoc.exists()) {
        const chatData = chatDoc.data();
  
        // Populate the chatMessages array with user message and GPT response
        setChatMessages([
          { text: chatData.message, sender: 'user' },
          { text: chatData.content, sender: 'bot' }
        ]);

        setShowMainSection(false);
  
        // Reset responseReceived to true to hide the input if this chat is complete
        setResponseReceived(true);
  
        // Set currentChatId for the selected chat
        setCurrentChatId(chatId);
      }
    } catch (error) {
      console.error("Error loading chat:", error);
    }
  };
  

  const handleNewChat = () => {
    setCurrentChatId(null);
    setChatMessages([]);
    setMessage("");
    setResponse("");
    setResponseReceived(false); // Reset to show the input area for new chat
    setShowMainSection(true);
  };

  const handleEditClick = (chatId, currentTitle) => {
    setEditingChatId(chatId);
    setEditedTitle(currentTitle);
  };

  const handleSaveTitle = async (chatId) => {
    try {
      if (!editedTitle.trim()) {
        console.error("Chat title cannot be empty.");
        return;
      }
      
      // Update the title in Firestore
      const chatRef = doc(firestore, "users", userId, chatPath, chatId); // Ensure correct path
      await updateDoc(chatRef, { title: editedTitle });
  
      // Update the chat title locally in the state
      setChats((prevChats) =>
        prevChats.map((chat) =>
          chat.id === chatId ? { ...chat, title: editedTitle } : chat
        )
      );
  
      setEditingChatId(null); // Exit edit mode
      console.log("Chat title updated successfully.");
    } catch (error) {
      console.error("Error updating chat title:", error);
    }
  };

  const handleTitleChange = (e) => setEditedTitle(e.target.value);
  

  return (
    <div className="chat-app">
      <PaymentModal 
        isOpen={showPopup} 
        onClose={() => setShowPopup(false)} 
      />
      {/* Hamburger Icon for Mobile */}
      {isMobile && (
        <button className="hamburger-icon" onClick={toggleSidebar}>
          ☰
        </button>
      )}
  
      {/* Sidebar */}
      <div className={`sidebar ${isMobile && sidebarOpen ? "open" : ""}`}>
        {isMobile && (
          <button className="close-btn" onClick={toggleSidebar}>
            ✕
          </button>
        )}
        <button className="new-chat-button" onClick={handleNewChat}>
          + New Chat
        </button>
        <h3 className="sidebar-title">Your Chats</h3>
        {chats.map((chat) => (
          <div key={chat.id} className="chat-item">
          {editingChatId === chat.id ? (
            <input
              className="edit-title-input"
              value={editedTitle}
              onChange={handleTitleChange}
              onBlur={() => handleSaveTitle(chat.id)}
              onKeyDown={(e) => {
                if (e.key === "Enter") handleSaveTitle(chat.id);
              }}
            />
          ) : (
            <>
              <span className="chat-title">{chat.title}</span>
              <FaEllipsisV
                className="edit-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditClick(chat.id, chat.title);
                }}
              />
            </>
          )}
        </div>
        ))}
      </div>
  
      {/* Main Chat Area */}
      <div className="main-content">
        <h2 className="chat-header">{heading}</h2>
        {showMainSection && mainSectionContent && (
          <div className="main-section">
            <h1>{mainSectionContent.title}</h1>
            <p>{mainSectionContent.description}</p>
          </div>
        )}
        <div className="chat-container" ref={chatContainerRef}>
          {chatMessages.map((msg, index) => (
            <div key={index} className={`chat-message ${msg.sender}`}>
              <p>{msg.text}</p>
            </div>
          ))}
        </div>
        {!responseReceived && (
          <div className="chat-input-container">
            <textarea
              className="chat-input"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Paste position description here..."
            />
            <button className="chat-send-button" onClick={handleSendMessage}>
              Send
            </button>
          </div>
        )}
      </div>
    </div>
  );  
}

export default Chat;


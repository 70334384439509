// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyAakyHcbZFRK_L6h3PXO2AhH4jaqRS6rpY",
    authDomain: "positionprep-39660.firebaseapp.com",
    projectId: "positionprep-39660",
    storageBucket: "positionprep-39660.appspot.com",
    messagingSenderId: "398639172066",
    appId: "1:398639172066:web:837bd58aeb3c750ef3d1bb",
    measurementId: "G-KN7PB89PC7"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
const provider = new GoogleAuthProvider();
const functions = getFunctions(app);

export { app, auth, provider, firestore, storage, functions, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword,
  sendEmailVerification, };

// src/Signup.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, provider, signInWithPopup, createUserWithEmailAndPassword, sendEmailVerification } from '../firebase';
import { useUser } from '../contexts/UserContext';
import './Login.css'; // Using the same styles as the login modal
import PaymentModal from './PaymentModal'; // Import the PaymentModal component

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false); // State for payment modal
  const { setUser, isSignupOpen, onSignupClose } = useUser(); // Context values
  const navigate = useNavigate();

  // Ensure modal opens reactively
  useEffect(() => {
    if (isPaymentModalOpen) {
      console.log("Payment modal is now open!"); // Debugging to confirm it reacts
    }
  }, [isPaymentModalOpen]);

  // Only render the modal if isSignupOpen is true
  if (!isSignupOpen) return null;

  // Handler for signing up with Google
  const handleGoogleSignUp = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      setIsPaymentModalOpen(true); // Open payment modal after signup
    } catch (error) {
      console.error("Error during Google sign-up:", error);
    }
  };

  // Handler for signing up with email and password
  const handleEmailPasswordSignUp = async (e) => {
    e.preventDefault();
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(result.user); // Send email verification
      setUser(result.user);
      alert("A verification email has been sent. Please check your inbox.");
      setIsPaymentModalOpen(true); // Open payment modal after signup
    } catch (error) {
      console.error("Error during email/password sign-up:", error);
    }
  };

  const handlePaymentClose = () => {
    setIsPaymentModalOpen(false); // Close payment modal
    navigate('/'); // Redirect to home page
    onSignupClose();
  };

  return (
    <div className="modal-overlay" onClick={onSignupClose}>
      <div className="login-modal" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onSignupClose}>×</button>
        <h2 className="login-title">Sign Up</h2>

        <form onSubmit={handleEmailPasswordSignUp} className="login-form">
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="login-input"
            />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="login-input"
            />
          </div>

          <button type="submit" className="login-button">
            Sign up with Email
          </button>

          <button type="button" className="login-button google" onClick={handleGoogleSignUp}>
            Sign up with Google
          </button>
        </form>

        <p className="signup-link">
          Already have an account? <a href="/login">Log in</a>
        </p>
      </div>

      {/* Payment Modal */}
      <PaymentModal
        isOpen={isPaymentModalOpen}
        onClose={handlePaymentClose}
      />
    </div>
  );
};

export default Signup;

import React, { useState, useEffect } from 'react';
import { auth, firestore, storage, app } from '../firebase'; // Import Firebase configuration
import { doc, deleteDoc, collection, getDocs, getDoc, query, where, onSnapshot } from 'firebase/firestore';
import { ref, deleteObject, listAll } from 'firebase/storage';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { onAuthStateChanged } from "firebase/auth";
import './Settings.css';
import Login from './Login';
import { getPortalUrl } from './CustomerPortal';

const SettingsPage = ({ onSignupOpen }) => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [requiresReauth, setRequiresReauth] = useState(false); // Track if reauthentication is needed
  const [confirmation, setConfirmation] = useState(false);
  const [accountStatus, setAccountStatus] = useState('');
  const [subscription, setSubscription] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null); // Handle unauthenticated state
      }
    });
    return () => unsubscribe();
  }, []);

    useEffect(() => {
      async function fetchUserSubscription() {
        try {
          if (!userId) return; // Ensure userId is available before fetching
          
          // Reference the Firestore collection for subscription details
          const subscriptionCollectionRef = collection(firestore, "users", userId, "subscriptions");
          
          // Create a query for active or trialing subscriptions
          const q = query(
            subscriptionCollectionRef,
            where("status", "in", ["trialing", "active"]) // Corrected "trailing" to "trialing"
          );
  
          // Listen to the query snapshot
          const unsubscribe = onSnapshot(
            q,
            (snapshot) => {
              console.log("Subscription snapshot", snapshot.docs.length);
  
              if (snapshot.docs.length === 0) {
                console.log("No active or trialing subscriptions found");
                setSubscription(false);
              } else {
                console.log("Active or trialing subscription found");
                setSubscription(true);
              }
            },
            (error) => {
              console.error("Error fetching subscription details:", error);
            }
          );
  
          // Clean up the listener when the component unmounts
          return () => unsubscribe();
        } catch (error) {
          console.error("Error fetching subscription details:", error);
        }
      }
  
      fetchUserSubscription();
    }, [userId, setSubscription]); // Dependencies include userId
    
    const handleDeleteAccount = async () => {
      const user = auth.currentUser;
  
      if (!user) {
        setAccountStatus("No user is currently signed in.");
        return;
      }
  
      const userId = user.uid;
  
      try {
        // Step 1: Delete nested collections under the user's document
        const deleteNestedCollections = async () => {
          const nestedCollections = ["chats", "checkout_sessions", "coverLetters", "payments", "settings", "subscriptions", "usage" ];
          for (const collectionName of nestedCollections) {
            const collectionRef = collection(firestore, "users", userId, collectionName);
            const querySnapshot = await getDocs(collectionRef);
  
            // Delete each document in the collection
            const deletePromises = querySnapshot.docs.map((doc) => deleteDoc(doc.ref));
            await Promise.all(deletePromises);
          }
        };
  
        // Step 2: Delete the user document in the "users" collection
        const deleteUserDocument = async () => {
          const userDocRef = doc(firestore, "users", userId);
          await deleteDoc(userDocRef);
        };
  
        // Step 3: Delete parsedResumeText from Firestore
        const deleteParsedResume = async () => {
          const parsedResumeRef = doc(firestore, "uploads", userId, "parsedDocuments", "parsedResume");
          await deleteDoc(parsedResumeRef);
          console.log("Parsed resume text deleted successfully.");
        };
  
        // Step 4: Delete files in Firebase Storage
        const deleteStorageData = async () => {
          const storagePath = `/uploads/${userId}`;
          const storageRef = ref(storage, storagePath);
          const listResult = await listAll(storageRef);
  
          // Delete each file in the storage folder
          const deletePromises = listResult.items.map((fileRef) => deleteObject(fileRef));
          await Promise.all(deletePromises);
        };
  
        // Execute all deletion steps
        await deleteNestedCollections();
        await deleteUserDocument();
        await deleteParsedResume();
        await deleteStorageData();
  
        // Step 5: Finally, delete the user account
        await user.delete();
  
        setAccountStatus("Account and data deleted successfully.");
        navigate("/"); // Redirect to homepage
      } catch (error) {
        if (error.code === "auth/requires-recent-login") {
          setAccountStatus("Reauthentication required. Please log in again to confirm deletion.");
          setRequiresReauth(true); // Set flag to open login modal
          setIsLoginModalOpen(true); // Open the login modal
        } else {
          setAccountStatus(`Error deleting account and data: ${error.message}`);
          console.error("Error deleting account and data:", error);
        }
      }
    };

    const manageSubscription = async () => {
      try {
        const portalURL = await getPortalUrl(app);
        window.location.assign(portalURL);
        console.log("Redirected to Stripe portal.");
      } catch (error) {
        console.error("Error managing subscription:", error);
        setSubscriptionStatus("Failed to open subscription portal.");
      }
    };

  return (
    <div className="settings-container">
      <h1 className="title">Settings</h1>

      <section className="account-settings">
        <h2>Account Settings</h2>
        <p>Delete your account permanently. This action cannot be undone.</p>
        {confirmation ? (
          <button className="confirm-btn" onClick={handleDeleteAccount}>Confirm Delete Account</button>
        ) : (
          <button className="delete-btn" onClick={() => setConfirmation(true)}>Delete Account</button>
        )}
        {isLoginModalOpen && (
        <Login
          isOpen={isLoginModalOpen}
          onClose={() => setIsLoginModalOpen(false)}
          onSignupOpen={onSignupOpen}
        />
      )}
        {accountStatus && <p className="status-message">{accountStatus}</p>}
      </section>

      <section className="subscription-settings">
        <h2>Subscription</h2>
        <p>Subscription Status: {subscription ? "Active" : "Inactive"}</p>
        {/* Conditionally render the cancel subscription button */}
        {subscription && (
          <button className="cancel-subscription-button" onClick={manageSubscription}>
            Manage Subscription
          </button>
        )}
        {subscriptionStatus && <p className="status-message">{subscriptionStatus}</p>}
      </section>
    </div>
  );
}

export default SettingsPage;

// src/components/Navbar.js
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import FileUpload from './FileUpload';
import FilePreview from './FilePreview';
import { ref, getDownloadURL } from "firebase/storage";
import { storage, auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import './Navbar.css';
import ProfileOrLogin from './ProfileOrLogin';



const Navbar = () => {
  const [resumeFile, setResumeFile] = useState(null);  // State to store resume file
  const [userId, setUserId] = useState(null);          // Store the user ID
  const user = auth.currentUser;
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleMenu = () => {
    setIsExpanded(!isExpanded);
  };

  // Reset isExpanded when switching to desktop view
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && isExpanded) {
        setIsExpanded(false);
      }
    };

    // Attach the resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isExpanded]);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        fetchResume(user.uid);  // Fetch resume if user is logged in
      }
    });
    return unsubscribe;
  }, []);

  const fetchResume = async (userId) => {
    try {
      const resumeRef = ref(storage, `uploads/${userId}/resume`);
      const url = await getDownloadURL(resumeRef);
      setResumeFile({ name: "Uploaded Resume", type: "application/pdf", url });
    } catch (error) {
      console.error("No resume found in storage for this user:", error);
    }
  };

  const handleResumeChange = async (file) => {
    // Update the resume file on upload
    setResumeFile({ name: file.name, type: file.type });
    fetchResume(userId);  // Fetch updated resume
  };

  return (
    <nav className="navbar">
      {/* First Row: Brand and Uploaded Resume */}
      <div className="navbar-row top-row">
        <Link to="/" className="brand">PositionPrep</Link>
        <div className="file-preview">
          {resumeFile && <FilePreview file={resumeFile} setFile={setResumeFile} />}
        </div>
        {/* Hamburger Icon for Mobile 
        <div className="hamburger-icon" onClick={toggleMenu}>
          ☰
        </div>*/}
      </div>

      {/* Second Row: Navigation Links and Profile */}
      <div className={`navbar-row navLinks bottom-row ${isExpanded ? "expanded" : ""}`}>
        <FileUpload onResumeChange={handleResumeChange} />
        <Link to="/cover-letter" className="nav-text-link">Cover Letter Generator</Link>
        <div className="profile-link">
          <ProfileOrLogin user={user} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

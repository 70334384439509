import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDoc, doc } from "firebase/firestore";
import { firestore } from '../firebase';

export const getPortalUrl = async (app) => {
    const auth = getAuth(app);
    const user = auth.currentUser;
  
    if (!user) {
      console.error("No user is logged in.");
      throw new Error("User not authenticated.");
    }
  
    try {
      // Fetch the stripeId from Firestore
      const userDoc = await getDoc(doc(firestore, "users", user.uid));
      const stripeId = userDoc.data()?.stripeId;
      console.log("Fetched Stripe ID:", stripeId);
  
      if (!stripeId) {
        console.error("Stripe ID not found for user:", user.uid);
        throw new Error("Stripe ID not found. Please ensure your account is linked to Stripe.");
      }
  
      // Log payload for httpsCallable
      const payload = {
        returnUrl: window.location.origin,
      };
      console.log("Payload for Stripe portal:", payload);
  
      const functions = getFunctions(app, "us-central1");
      const functionRef = httpsCallable(
        functions,
        "ext-firestore-stripe-payments-createPortalLink"
      );
  
      const { data } = await functionRef(payload);
  
      if (data?.url) {
        console.log("Stripe portal URL:", data.url);
        return data.url;
      } else {
        console.error("No URL returned from Stripe portal function.");
        throw new Error("No URL returned from Stripe portal function.");
      }
    } catch (error) {
      console.error("Error fetching Stripe portal URL:", error.message);
      throw error;
    }
  };

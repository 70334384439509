// src/PaymentModal.js
import React, { useState } from 'react';
import './PaymentModal.css'; // Optional CSS for styling
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, addDoc, onSnapshot } from 'firebase/firestore';


const PaymentModal = ({ isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);
  // Initialize Firestore and Authentication
  const db = getFirestore();
  const auth = getAuth();

  const handleCheckout = async () => {
    const currentUser = auth.currentUser;
  
    if (!currentUser) {
      alert('You must be signed in to subscribe.');
      return;
    }
  
    try {
      // Add a document to the 'checkout_sessions' sub-collection
      const checkoutSessionsRef = collection(db, 'users', currentUser.uid, 'checkout_sessions');
      const docRef = await addDoc(checkoutSessionsRef, {
        price: 'price_1QTCFIAWnK0AWkSwO3D95JFB', // Replace with your Stripe Price ID
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });
  
      // Listen for changes to the checkout session document
      onSnapshot(docRef, (doc) => {
        const { error, url } = doc.data();
  
        if (error) {
          alert(`An error occurred: ${error.message}`);
          console.error('Stripe Checkout error:', error);
        }
  
        if (url) {
          // Redirect to the Stripe Checkout page
          window.location.assign(url);
        }
      });
    } catch (error) {
      console.error('Error creating checkout session:', error);
      alert('Failed to create checkout session. Please try again.');
    }
  };
  

  return (
    isOpen && (
      <div className="modal-overlay" onClick={onClose}>
        <div className="payment-modal" onClick={(e) => e.stopPropagation()}>
          <button className="close-button" onClick={onClose}>×</button>
          <h2 className="payment-title">Subscribe to Premium</h2>
          <p>Get access to exclusive features by subscribing to our premium plan.</p>
          <button
            onClick={handleCheckout}
            disabled={loading}
            className="payment-button"
          >
            {loading ? 'Processing...' : 'Subscribe Now'}
          </button>
        </div>
      </div>
    )
  );
};

export default PaymentModal;

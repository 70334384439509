// ProfileOrLogin.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './ProfileOrLogin.css';
import Login from './Login';
import Signup from './Signup';
import './Login.css';
import './Signup.css';
import { useUser } from '../contexts/UserContext'; // Import useUser from UserContext
import { useNavigate } from 'react-router-dom';

function ProfileOrLogin() {
  const { user, handleLogout, isSignupOpen, onSignupOpen, onSignupClose } = useUser(); // Use context values
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoginOpen, setLoginOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  return (
    <div className="profile-container">
      {/* Profile or Login */}
      {user ? (
        <>
          <div onClick={toggleModal} className="profile-link">
            {user.photoURL ? (
              <img
                src={user.photoURL}
                alt="Profile"
                className="profile-picture"
                referrerPolicy="no-referrer"
              />
            ) : (
              <span className="profile-text">Profile</span>
            )}
          </div>
          {/* Modal popup */}
          {isModalOpen && (
            <div className="modal-popup">
              <Link to="/settings" className="modal-option" onClick={() => setModalOpen(false)}>
                Settings
              </Link>
              <button className="modal-option" onClick={() => handleLogout(navigate)}>Log Out</button>
            </div>
          )}
        </>
      ) : (
        <>
          <a
            href="#login"
            className="open-login-modal-link"
            onClick={(e) => {
              e.preventDefault();
              setLoginOpen(true);
            }}
          >
            Login
          </a>
          <Login
            isOpen={isLoginOpen}
            onClose={() => setLoginOpen(false)}
            onSignupOpen={onSignupOpen} // Use onSignupOpen from UserContext
          />
          <Signup
            isOpen={isSignupOpen} // Use isSignupOpen from UserContext
            onClose={onSignupClose} // Use onSignupClose from UserContext
          />
        </>
      )}
    </div>
  );
}

export default ProfileOrLogin;
